import React, {Dispatch, SetStateAction} from "react";
import IMPORT_IMAGE from "../../../assets/img/Import_Image.png";
import {FormTitle} from "../StyledComponents";
import {Launch, Refresh} from "@material-ui/icons";
import {Link} from "@material-ui/core";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {appContextSelector} from "../../app-context/appContextSlice";
import {
    deviceContextActions,
    deviceContextSelector, getDeviceImportResult,
    getDeviceImportStatuses,
    importDevices
} from "../../devices/deviceSlice";
import {InfiniteScrollList, InfiniteScrollProps} from "../InfiniteScroll";
import {LoadingProgressBar} from "../LoadingProgressBar";
import {PORTAL_UPLOAD_STATUS_STATUSES, PortalUploadStatusDto} from "../../../dtos/PortalUploadStatus";
import {
    employeeContextActions, employeeContextSelector,
    getEmployeeImportResult,
    getEmployeeImportStatuses,
    importEmployees
} from "../../employees/employeeSlice";
import { getLocalizedString } from "../../../util/Localization";
import {ARCButton} from "../design-system/ARCButton";

interface ImportUploadDataProps {
    isDeviceImport: boolean,
    uploadFile: File | undefined,
    setUploadFile: Dispatch<SetStateAction<File | undefined>>,
    previousStatus: PortalUploadStatusDto | undefined
    getPreviousStatusAndNavigateToUploadFile: () => void
}

export const ImportUploadData: React.FC<ImportUploadDataProps> = ({ 
    isDeviceImport,
    uploadFile, 
    setUploadFile, 
    previousStatus,
    getPreviousStatusAndNavigateToUploadFile
}) => {
    const navigate = useNavigate();
    const context = useAppSelector(appContextSelector);
    const dispatch = useAppDispatch();
    const deviceContext = useAppSelector(deviceContextSelector);
    const employeeContext = useAppSelector(employeeContextSelector);
    const deviceResult = deviceContext.importResult;
    const employeeResult = employeeContext.importResult;
    const [progress, setProgress] = React.useState<number>(0);
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const [completedStatus, setCompletedStatus] = React.useState<PortalUploadStatusDto | undefined>(undefined);
    const ERROR_MESSAGE = getLocalizedString('import.result.error', 'An unexpected error occurred while processing this file.');

    function processImportStatusInterval(importResult: PortalUploadStatusDto, intervalId: NodeJS.Timeout | undefined) {
        if (importResult.status === PORTAL_UPLOAD_STATUS_STATUSES.SUCCESS) {
            setProgress(100);
            if (intervalId) {
                clearInterval(intervalId);
            }
            setCompletedStatus(importResult)
        } else if (importResult.status === PORTAL_UPLOAD_STATUS_STATUSES.ERROR) {
            setProgress(100);
            setErrorMessage(ERROR_MESSAGE);
            if (intervalId) {
                clearInterval(intervalId);
            }
            setCompletedStatus(importResult)
        } else if (importResult.processedRows && importResult.totalRows) {
            const statusProgress = Math.ceil((importResult.processedRows / importResult.totalRows) * 100);
            setProgress(statusProgress);
        }
    }
    
    function getEmployeeImportStatusProgress(currentStatus: PortalUploadStatusDto, locationId: number) {
        let intervalId: NodeJS.Timeout | undefined = undefined;
        if (currentStatus.status === PORTAL_UPLOAD_STATUS_STATUSES.IN_PROGRESS || currentStatus.status === PORTAL_UPLOAD_STATUS_STATUSES.CREATED) {
            intervalId = setInterval(() => {
                dispatch(getEmployeeImportResult({
                    locationId: locationId,
                    portalUploadStatusId: currentStatus.portalUploadStatusId
                })).then((importResult) => {
                    processImportStatusInterval(importResult.payload, intervalId);
                })
            }, 5000); // 5 seconds
        } else if (currentStatus.status === PORTAL_UPLOAD_STATUS_STATUSES.SUCCESS) {
            setProgress(100);
            setCompletedStatus(currentStatus)
        } else if (currentStatus.status === PORTAL_UPLOAD_STATUS_STATUSES.ERROR) {
            setProgress(100);
            setCompletedStatus(currentStatus)
            setErrorMessage(ERROR_MESSAGE);
        }
        return intervalId;
    }
    
    function getDeviceImportStatusProgress(currentStatus: PortalUploadStatusDto, locationId: number) {
        let intervalId: NodeJS.Timeout | undefined = undefined;
        if (currentStatus.status === PORTAL_UPLOAD_STATUS_STATUSES.IN_PROGRESS || currentStatus.status === PORTAL_UPLOAD_STATUS_STATUSES.CREATED) {
            intervalId = setInterval(() => {
                dispatch(getDeviceImportResult({
                    locationId: locationId,
                    portalUploadStatusId: currentStatus.portalUploadStatusId
                })).then((importResult) => {
                    processImportStatusInterval(importResult.payload, intervalId);
                })
            }, 5000); // 5 seconds
        } else if (currentStatus.status === PORTAL_UPLOAD_STATUS_STATUSES.SUCCESS) {
            setProgress(100);
            setCompletedStatus(currentStatus)
        } else if (currentStatus.status === PORTAL_UPLOAD_STATUS_STATUSES.ERROR) {
            setProgress(100);
            setCompletedStatus(currentStatus)
            setErrorMessage(ERROR_MESSAGE);
        }
        return intervalId;
    }

    function importDataAndGetStatus(locationId: number) {
        let intervalId: NodeJS.Timeout | undefined = undefined;
        if (isDeviceImport) {
            if (uploadFile) {
                dispatch(deviceContextActions.resetImportDevice());
                dispatch(importDevices({
                    file: uploadFile,
                    locationId: locationId
                })).then((action) => {
                    setUploadFile(undefined);
                    if (locationId && action.payload && action.payload.successMessage) {
                        dispatch(getDeviceImportStatuses({locationId: locationId})).then((statusesResponse) => {
                            if (statusesResponse && statusesResponse.payload.length > 0) {
                                let currentStatus = statusesResponse.payload[0];
                                intervalId = getDeviceImportStatusProgress(currentStatus, locationId);
                            }
                        })
                    } else {
                        setProgress(100);
                        setErrorMessage(ERROR_MESSAGE);
                    }
                });
            } else if (previousStatus) {
                intervalId = getDeviceImportStatusProgress(previousStatus, locationId);
            }
        } else {
            if (uploadFile) {
                dispatch(employeeContextActions.resetImportEmployee());
                dispatch(importEmployees({
                    file: uploadFile,
                    locationId: locationId
                })).then((action) => {
                    setUploadFile(undefined);
                    if (locationId && action.payload && action.payload.successMessage) {
                        dispatch(getEmployeeImportStatuses({locationId: locationId})).then((statusesResponse) => {
                            if (statusesResponse && statusesResponse.payload.length > 0) {
                                let currentStatus = statusesResponse.payload[0];
                                intervalId = getEmployeeImportStatusProgress(currentStatus, locationId);
                            }
                        })
                    } else {
                        setProgress(100);
                        setErrorMessage(ERROR_MESSAGE);
                    }
                });
            } else if (previousStatus) {
                intervalId = getEmployeeImportStatusProgress(previousStatus, locationId);
            }
        }
        return intervalId;
    }

    React.useEffect( () => {
        const locationId = context.currentLocation?.locationId;
        let intervalId: NodeJS.Timeout | undefined = undefined;
        if (locationId) {
            intervalId = importDataAndGetStatus(locationId);
        }
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.currentLocation?.locationId]);
    
    const resetImportResult = React.useCallback(() => {
        if (isDeviceImport) {
            dispatch(deviceContextActions.resetImportDevice())
        } else {
            dispatch(employeeContextActions.resetImportEmployee())
        }
    }, [isDeviceImport, dispatch])
    
    const successNumber = React.useMemo(() => {
        let success = undefined;
        if (completedStatus) {
            success = (completedStatus.updatedRows ?? 0) + (completedStatus.uploadedRows ?? 0)
        }
        return success;
    }, [completedStatus])
    
    const transferredNumber = React.useMemo(() => {
        let success = undefined;
        if (completedStatus) {
            success = completedStatus.transferredRows
        }
        return success;
    }, [completedStatus])
    
    const errorNumber = React.useMemo(() => {
        let error = undefined;
        if (completedStatus) {
            error = completedStatus.totalRows - ((successNumber ?? 0) + (completedStatus.transferredRows ?? 0))
        }
        return error;
    }, [completedStatus, successNumber])
    
    const progressBarMessage = React.useMemo(() => {
        if (isDeviceImport) {
            if (deviceResult?.status !== undefined && deviceResult.status !== PORTAL_UPLOAD_STATUS_STATUSES.CREATED) {
                return undefined
            } else {
                return (
                    <div className={'loading-wrapper'}>
                        <span className={'loading'}>{getLocalizedString('import.waiting', 'Waiting to begin import')}</span>
                    </div>
                )
            }
        } else {
            if (employeeResult?.status !== undefined && employeeResult?.status !== PORTAL_UPLOAD_STATUS_STATUSES.CREATED) {
                return undefined
            } else {
                return (
                    <div className={'loading-wrapper'}>
                        <span className={'loading'}>{getLocalizedString('import.waiting', 'Waiting to begin import')}</span>
                    </div>
                )
            }
        }
    }, [deviceResult?.status, employeeResult?.status, isDeviceImport])

    const deviceDuplicateListComponent = React.useMemo(() => {
        const handleItemsRendered = () => {
        }

        let duplicateList = undefined;
        if (deviceResult?.duplicateList && deviceResult.duplicateList.length > 0) {
            duplicateList = deviceResult.duplicateList.split(',')
        }

        const RenderListItem: InfiniteScrollProps<string>['renderItem'] = (duplicate, index) => {
            const portalUploadStatusId = deviceResult?.portalUploadStatusId;
            if (duplicate && portalUploadStatusId) {
                const tag = duplicate.split('(')[0].trim();
                const idNumber = duplicate.split('(')[1].split(')')[0].trim();
                let type: string | undefined = undefined;
                if (duplicate.includes('[')) {
                    type = duplicate.split('[')[1].split(']')[0].trim();
                }
                const transferred = duplicate.includes('TRANSFERRED')
                return (
                    <div>
                        <ul style={{listStyleType: 'disc', paddingLeft: '20px'}}>
                            <li key={index}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                <span style={{
                                    fontWeight: 'bold',
                                    fontSize: '13px'
                                }}>
                                    {tag}
                                </span>
                                    <span style={{
                                        fontSize: '13px',
                                        marginLeft: '5px'
                                    }}>
                                    {'(' + idNumber + ')'}
                                </span>
                                    {!transferred ?
                                        <>
                                            <Link
                                                style={{marginLeft: '5px', textDecoration: 'underline', color: '#517D9E', fontSize: '12px'}}
                                                target={'_blank'}
                                                rel={'noopener noreferrer'}
                                                href={`/devices/create?locationId=${context.currentLocation?.locationId}&deviceIdNumber=${idNumber}` +
                                                        `&deviceTag=${tag}&deviceType=${type}&uploadIndex=${index}&portalUploadStatusId=${portalUploadStatusId}`}
                                            >
                                                {getLocalizedString('import.result.register', 'Register Device')}
                                            </Link>
                                            <Launch style={{
                                                width: '15px',
                                                height: '15px',
                                                marginLeft: '5px',
                                                marginTop: '2px'
                                            }}/>
                                        </>
                                        :
                                        <span style={{marginLeft: '5px', color: '#009823', fontSize: '12px'}}>
                                            {getLocalizedString('import.result.register.success', 'Device Registered Successfully')}
                                        </span>
                                    }
                                </div>
                            </li>
                        </ul>
                    </div>
                )
            } else {
                return <></>
            }
        }

        return (
            <>
                {(duplicateList && duplicateList.length > 0) &&
                    <>
                        <div className={'section-header'}>
                            {getLocalizedString('import.result.device.transferGuide', 'The following devices have info that matches existing devices. Please register them individually:')}
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', marginTop: '32px'}}>
                            <div className={'importuploaddata-duplicate-div'}>
                                <InfiniteScrollList
                                    data={duplicateList}
                                    renderItem={RenderListItem}
                                    initialHeight={40}
                                    onItemsRendered={handleItemsRendered}
                                    padding={0}
                                />
                            </div>
                            <div style={{display: 'flex', cursor: 'pointer'}}>
                                <Refresh
                                    onClick={() => {
                                        if (deviceResult?.portalUploadStatusId && context.currentLocation?.locationId) {
                                            dispatch(getDeviceImportResult({
                                                portalUploadStatusId: deviceResult?.portalUploadStatusId,
                                                locationId: context.currentLocation?.locationId
                                            }))
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </>
                }
            </>
        )

    }, [context.currentLocation?.locationId, deviceResult?.duplicateList, deviceResult?.portalUploadStatusId, dispatch])

    const employeeDuplicateListComponent = React.useMemo(() => {
        const handleItemsRendered = () => {
        }
        let duplicateList = undefined;
        if (employeeResult?.duplicateList && employeeResult.duplicateList.length > 0) {
            duplicateList = employeeResult.duplicateList.split(',')
        }
        if (employeeResult?.duplicateEmailList && employeeResult.duplicateEmailList.length > 0) {
            if (duplicateList) {
                duplicateList = duplicateList.concat(employeeResult.duplicateEmailList.split(','));
            } else {
                duplicateList = employeeResult.duplicateEmailList.split(',');
            }
        }
        if (employeeResult?.duplicateCredentialList && employeeResult.duplicateCredentialList.length > 0) {
            if (duplicateList) {
                duplicateList = duplicateList.concat(employeeResult.duplicateCredentialList.split(','));
            } else {
                duplicateList = employeeResult.duplicateCredentialList.split(',');
            }
        }


        const RenderListItem: InfiniteScrollProps<string>['renderItem'] = (duplicate, index) => {
            const portalUploadStatusId = employeeResult?.portalUploadStatusId;
            if (duplicate && portalUploadStatusId) {
                return (
                    <div>
                    <ul style={{listStyleType: 'disc', paddingLeft: '20px'}}>
                            <li key={index}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                <span style={{
                                    fontWeight: 'bold',
                                    fontSize: '13px'
                                }}>
                                    {duplicate}
                                </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                )
            } else {
                return <></>
            }
        }

        return (
            <>
                {(duplicateList && duplicateList.length > 0) &&
                    <>
                        <div className={'section-header'}>
                            {getLocalizedString('import.result.employee.transferGuide', 'The following user(s) were not imported because they are duplicate rows or have information that matches existing user(s):')}
                        </div>
                        <div className={'importuploaddata-duplicate-div'} >
                            <InfiniteScrollList
                                data={duplicateList}
                                renderItem={RenderListItem}
                                initialHeight={30}
                                onItemsRendered={handleItemsRendered}
                                padding={0}
                            />
                        </div>
                    </>
                }
            </>
        )

    }, [employeeResult?.portalUploadStatusId, employeeResult?.duplicateEmailList, employeeResult?.duplicateList, employeeResult?.duplicateCredentialList])

    return (
        <>
            <div className={'importlanding-container-div'}>
                <div className={'importlanding-image-div'}>
                    <img src={IMPORT_IMAGE} alt={'Import Icon'}/>
                </div>
                <div className={'importlanding-main-div'}>
                    <FormTitle>
                        {(progress === 100 ? getLocalizedString('import.result.completed', (isDeviceImport ? 'Device' : 'User') + ' Import Complete!', [(isDeviceImport ? getLocalizedString('device', 'Device') : getLocalizedString('employee', 'User'))]) : getLocalizedString('import.result.loading', (isDeviceImport ? 'Device' : 'User') + ' Import in Progress', [isDeviceImport ? getLocalizedString('device', 'Device') : getLocalizedString('employee', 'User')]))}
                    </FormTitle>
                    <div style={{marginTop: '16px'}}>
                        {!!successNumber &&
                        <div className={'section-header'}>
                            <span className={'section-header'} style={{color: '#008651'}}>{successNumber}</span>{isDeviceImport ? getLocalizedString('import.result.device.successCount', ' devices imported') : getLocalizedString('import.result.employee.successCount', ' users imported')}
                        </div>
                        }
                        {!!transferredNumber &&
                        <div className={'section-header'}>
                            <span className={'section-header'} style={{color: '#00975B'}}>{transferredNumber}</span>{isDeviceImport ? getLocalizedString('import.result.device.transferCount', ' devices transferred') : getLocalizedString('import.result.employee.transferCount', ' users transferred')}
                        </div>
                        }
                        {!!errorNumber &&
                        <div className={'section-header'}>
                            <span className={'section-header'} style={{color: 'red'}}>{errorNumber}</span>{isDeviceImport ? getLocalizedString('import.result.device.errorCount', ' devices not imported') : getLocalizedString('import.result.employee.errorCount', ' users not imported')}
                        </div>
                        }
                    </div>
                    <div style={{width: '400px', display: 'flex', flexDirection: 'row', alignItems: 'end', marginTop: '16px', marginBottom: '32px'}}>
                        <LoadingProgressBar progress={progress} error={!!errorMessage} message={errorMessage ?? progressBarMessage}></LoadingProgressBar>
                    </div>
                    {isDeviceImport ?
                        deviceDuplicateListComponent
                        :
                        employeeDuplicateListComponent
                    }
                    <div style={{display: 'flex', marginTop: '32px'}}>
                        {isDeviceImport ?
                        <ARCButton
                            id={`import-users-view-devices-nav-button`}
                            fill={'filled'}
                            variant={'primary'}
                            size={'md'}
                            disabled={progress !== 100}
                            onClick={() => {
                                navigate(`/devices/view/locationId=${context.currentLocation?.locationId}/brandId=${context.currentBrand?.brandId}`)
                            }}
                            label={getLocalizedString('import.result.device.link', 'Go to Devices')}
                        >
                        </ARCButton>
                        :
                        <ARCButton
                            id={`import-users-view-users-nav-button`}
                            fill={'filled'}
                            variant={'primary'}
                            size={'md'}
                            disabled={progress !== 100}
                            onClick={() => {
                                navigate(`/employees/view/locationId=${context.currentLocation?.locationId}/brandId=${context.currentBrand?.brandId}`)
                            }}
                            label={getLocalizedString('import.result.employee.link', 'Go to Users')}
                        >
                        </ARCButton>
                        }
                    </div>
                    {progress === 100 &&
                    <div style={{display: 'flex', marginTop: '32px'}}>
                        <ARCButton
                            id={`import-${isDeviceImport ? 'devices' : 'users'}-upload-again-button`}
                            fill={'filled'}
                            variant={'secondary'}
                            size={'md'}
                            onClick={() => {
                                resetImportResult()
                                getPreviousStatusAndNavigateToUploadFile()
                            }}
                            label={getLocalizedString('import.result.newUpload', 'Upload Another File')}
                        >
                        </ARCButton>
                    </div>
                    }
                </div>
            </div>
        </>
    )
}

