import * as React from 'react';
import {useNavigate} from "react-router-dom";
import "./PrivacyTermsPage.css";
import {AppWithFooter} from "../../App";
import AppBar from "../appbar/AppBar";
import {getLocale, getLocalizedString} from "../../util/Localization";
import {PrivacyUS} from "./privacy/PrivacyUS";
import {LOCALE_FR_CA} from "../../dtos/API_DOMAINS";
import {TermsPage} from "./terms/TermsPage";
import {PrivacyCA} from "./privacy/PrivacyCA";
import {ARCSecondaryNav} from "../ui-components/design-system/secondary-nav/ARCSecondaryNav";

const PRIVACY_TERMS_TYPES = {
    privacyUS: 'PRIVACY_US',
    privacyCA: 'PRIVACY_CA',
    terms: 'TERMS',
}
type PrivacyTermsPageProps = {
    isTerms: boolean
}
export const PrivacyTermsPage:React.ComponentType<PrivacyTermsPageProps> = ({isTerms}) => {
    const navigate = useNavigate();
    const isEnglish = getLocale() !== LOCALE_FR_CA;
    const backButtonPress = React.useCallback(() => {
        navigate(-1);
    },[navigate]);
    
    const [page, setPage] = React.useState(isTerms ? PRIVACY_TERMS_TYPES.terms : PRIVACY_TERMS_TYPES.privacyUS);
    
    return (
     <AppWithFooter>
         <AppBar title={<></>} right={!localStorage.getItem("token") ? <div className={'privacyterms-login-text'} id={'privacy-login-button'} onClick={() => navigate('/login')}>{'Log In'}</div> : undefined} logoNonInteractive={true}/>
         <main className={'o-main'}>
            <div className={'l-wrap l-container--lg'}>
                
                <ARCSecondaryNav
                    options={[
                        {
                            isSelected: page === PRIVACY_TERMS_TYPES.privacyUS,
                            label: getLocalizedString('privacyterms.title1','Privacy Policy (US)'),
                            onClick: () => setPage(PRIVACY_TERMS_TYPES.privacyUS),
                            href: ""
                        },
                        {
                            isSelected: page === PRIVACY_TERMS_TYPES.privacyCA,
                            label: getLocalizedString('privacyterms.title2', 'Privacy Policy (Canada)'),
                            onClick: () => setPage(PRIVACY_TERMS_TYPES.privacyCA),
                            href: ""
                        },
                        {
                            isSelected: page === PRIVACY_TERMS_TYPES.terms,
                            label: getLocalizedString('privacyterms.title3', 'Terms & Conditions'),
                            onClick: () => setPage(PRIVACY_TERMS_TYPES.terms),
                            href: ""
                        },
                    ]}
                    id={'privacy-terms-secondary-nav'}
                />
                {page === PRIVACY_TERMS_TYPES.privacyUS &&
                    <PrivacyUS backButtonPress={backButtonPress} isEnglish={isEnglish}/>
                }
                {page === PRIVACY_TERMS_TYPES.privacyCA &&
                    <PrivacyCA backButtonPress={backButtonPress} isEnglish={isEnglish}/>
                }
                {page === PRIVACY_TERMS_TYPES.terms &&
                    <TermsPage backButtonPress={backButtonPress} isEnglish={isEnglish}/>
                }
             </div>
         </main>
     </AppWithFooter>
    )
}
