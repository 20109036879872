import React, {Dispatch, SetStateAction} from "react";
import {IconButton} from "@material-ui/core";
import {ArrowBack} from "@material-ui/icons";
import {FormTitle} from "../StyledComponents";
import {formatNameListGrammatically} from "../../../util/TextUtils";
import {SectionalDropdown, SectionDto} from "../SectionalDropdown";
import {ImportChildComponentNames} from "./ImportUploadFile";
import {DeviceValidationDto} from "../../../util/DeviceFileUploadValidation";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {appContextSelector} from "../../app-context/appContextSlice";
import {EmployeeValidationDto} from "../../../util/EmployeeFileUploadValidation";
import {
    KIOSK_EMPLOYEE,
    KIOSK_MANAGER,
    KIOSK_NONE,
    KIOSK_TEAM_LEAD,
    PORTAL_ADMIN,
    PORTAL_SUPER_ADMIN
} from "../../app-context/containers/UserPermissions";
import {devicePickupPermissionContextSelector} from "../../devices/devicePickupPermissionSlice";
import {getLocalizedString} from "../../../util/Localization";
import {deviceContextActions} from "../../devices/deviceSlice";
import {employeeContextActions} from "../../employees/employeeSlice";
import {ARCButton} from "../design-system/ARCButton";
import {ARCTextLink} from "../design-system/ARCTextLink";

interface ReviewImportDataProps {
    isDeviceImport: boolean,
    setCurrentChildComponent: Dispatch<SetStateAction<string>>,
    deviceValidationDto: DeviceValidationDto | undefined,
    employeeValidationDto: EmployeeValidationDto | undefined,
    getPreviousStatusAndNavigateToUploadFile: () => void,
    idNumberRequirements: string | undefined
}

export const ReviewImportData: React.FC<ReviewImportDataProps> = ({
    isDeviceImport, 
    setCurrentChildComponent,
    deviceValidationDto,
    employeeValidationDto,
    getPreviousStatusAndNavigateToUploadFile,
    idNumberRequirements
}) => {
    const context = useAppSelector(appContextSelector);
    const devicePermissionsContext = useAppSelector(devicePickupPermissionContextSelector);
    const dispatch = useAppDispatch();

    const deviceTypeDescriptionText = React.useMemo(() => {
        if (context.currentLocation?.deviceTypes) {
            const deviceTypes = context.currentLocation.deviceTypes;
            return formatNameListGrammatically(deviceTypes, 'deviceTypeName');
        }
        return undefined;
    }, [context.currentLocation?.deviceTypes])
    
    const deviceGroupDescriptionText = React.useMemo(() => {
        if (devicePermissionsContext.permissions) {
            const permissions = devicePermissionsContext.permissions;
            return formatNameListGrammatically(permissions, 'permissionName');
        }
        return undefined;
    }, [devicePermissionsContext.permissions])
    
    const optionsSuccess: SectionDto[] | undefined = React.useMemo(() => {
        if (isDeviceImport && deviceValidationDto) {
            return (
                deviceValidationDto?.validDevices ?
                    [
                        {
                            header: '',
                            headerDescription: '',
                            bulletPoints: deviceValidationDto?.validDevices
                        }
                    ] : undefined
            )
        } else if (employeeValidationDto) {
            return (
                employeeValidationDto?.validEmployees ?
                    [
                        {
                            header: '',
                            headerDescription: '',
                            bulletPoints: employeeValidationDto?.validEmployees
                        }
                    ] : undefined
            )
        }
    },[isDeviceImport, deviceValidationDto, employeeValidationDto]);
    
    const optionsError: SectionDto[] | undefined = React.useMemo(() => {
        if (isDeviceImport && deviceValidationDto) {
            return (
                [
                    {
                        header: getLocalizedString('import.template.device.deviceType.rejected', 'Incorrect Device Type Format'),
                        headerDescription: getLocalizedString('import.template.device.deviceType.rejected.desc', 'Device Types must be ' + deviceTypeDescriptionText, [`${deviceTypeDescriptionText}`]),
                        bulletPoints: deviceValidationDto.deviceTypeErrors
                    },
                    {
                        header: getLocalizedString('import.template.device.deviceIdNumber.rejected', 'Incorrect Device ID Format'),
                        headerDescription: idNumberRequirements,
                        bulletPoints: deviceValidationDto.deviceIdNumberErrors
                    },
                    {
                        header: getLocalizedString('import.template.device.deviceTag.rejected', 'Incorrect Device Serial Format'),
                        headerDescription: getLocalizedString('import.template.device.deviceTag.rejected.desc', 'Device Serial Number must be under 25 characters and only contain letters or numbers '),
                        bulletPoints: deviceValidationDto.deviceTagErrors
                    },
                    {
                        header: 'Duplicate Row',
                        headerDescription: 'The following rows have the same info as other rows in the uploaded file ',
                        bulletPoints: deviceValidationDto.duplicateDevices
                    }
                ]
            )
        } else if (employeeValidationDto) {
            return (
                [
                    {
                        header: getLocalizedString('import.template.employee.firstName.rejected', 'Incorrect First Name format'),
                        headerDescription: getLocalizedString('import.template.employee.firstName.rejected.desc', 'First Name can only include letters, spaces, hyphens, and apostrophes'),
                        bulletPoints: employeeValidationDto.firstNameErrors
                    },
                    {
                        header: getLocalizedString('import.template.employee.middleName.rejected', 'Incorrect Middle Initial format'),
                        headerDescription: getLocalizedString('import.template.employee.middleName.rejected.desc', 'Middle Initial can only include letters'),
                        bulletPoints: employeeValidationDto.middleInitialErrors
                    },
                    {
                        header: getLocalizedString('import.template.employee.lastName.rejected', 'Incorrect Last Name format'),
                        headerDescription: getLocalizedString('import.template.employee.lastName.rejected.desc', 'Last Name can only include letters, spaces, hyphens, and apostrophes'),
                        bulletPoints: employeeValidationDto.lastNameErrors
                    },
                    {
                        header: getLocalizedString('import.template.employee.kioskGroup.rejected', 'Incorrect Kiosk Access format'),
                        headerDescription: getLocalizedString('import.template.employee.kioskGroup.rejected.desc', 'Kiosk Access must be ' + KIOSK_NONE + ', ' + KIOSK_EMPLOYEE + ', ' + KIOSK_TEAM_LEAD + ', or ' + KIOSK_MANAGER, [getLocalizedString('employee.kioskGroup.none', KIOSK_NONE), getLocalizedString('employee.kioskGroup.associate', KIOSK_EMPLOYEE), getLocalizedString('employee.kioskGroup.teamLead', KIOSK_TEAM_LEAD), getLocalizedString('employee.kioskGroup.manager', KIOSK_MANAGER)]),
                        bulletPoints: employeeValidationDto.kioskGroupErrors
                    },
                    {
                        header: getLocalizedString('import.template.employee.badgeId.rejected', 'Incorrect Badge ID format'),
                        headerDescription: idNumberRequirements,
                        bulletPoints: employeeValidationDto.badgeIdErrors
                    },
                    {
                        header: getLocalizedString('import.template.employee.status.rejected', 'Incorrect Status format'),
                        headerDescription: getLocalizedString('import.template.employee.status.rejected.desc', 'Status must be Active or Inactive'),
                        bulletPoints: employeeValidationDto.statusErrors
                    },
                    {
                        header: getLocalizedString('import.template.employee.devicePickupPermission.rejected', 'Incorrect Device Group format'),
                        headerDescription: deviceGroupDescriptionText,
                        bulletPoints: employeeValidationDto.deviceGroupErrors
                    },
                    {
                        header: getLocalizedString('import.template.employee.portalGroup.rejected', 'Incorrect Client Portal Access format'),
                        headerDescription: getLocalizedString('import.template.employee.portalGroup.rejected.desc', 'Client Portal Access format must be None, ' + PORTAL_ADMIN + ', or' + PORTAL_SUPER_ADMIN, [getLocalizedString('employee.portalGroup.none', 'None'), getLocalizedString('employee.portalGroup.admin', PORTAL_ADMIN), getLocalizedString('employee.portalGroup.superAdmin', PORTAL_SUPER_ADMIN)]),
                        bulletPoints: employeeValidationDto.portalAccessErrors
                    },
                    {
                        header: getLocalizedString('import.template.employee.email.rejected', 'Incorrect Email format'),
                        headerDescription: getLocalizedString('import.template.employee.email.rejected.desc', 'Must be a valid email address'),
                        bulletPoints: employeeValidationDto.emailErrors
                    },
                    {
                        header: 'Duplicate Row',
                        headerDescription: 'The following rows have the same info as other rows in the uploaded file ',
                        bulletPoints: employeeValidationDto.duplicateEmployees
                    }
                ]
            )
        }
        return undefined
    }, [employeeValidationDto,
                deviceValidationDto,
                idNumberRequirements,
                deviceGroupDescriptionText,
                deviceTypeDescriptionText,
                isDeviceImport]);
    
    const validDropdownTitle = React.useMemo(() => {
        if (isDeviceImport && deviceValidationDto) {
            const totalValidDevices = deviceValidationDto?.validDevices.length ?? 0;
            return totalValidDevices + getLocalizedString('import.review.device.totalSuccess', ' devices will be imported');
        } else if (employeeValidationDto) {
            const totalValidEmployees = employeeValidationDto?.validEmployees.length ?? 0;
            return totalValidEmployees + getLocalizedString('import.review.employee.totalSuccess', ' users will be imported');
        }
        return undefined;
    }, [deviceValidationDto, employeeValidationDto, isDeviceImport])
    
    const invalidDropdownTitle = React.useMemo(() => {
        if (isDeviceImport && deviceValidationDto) {
            const totalValidDevices = deviceValidationDto?.invalidDevicesCount ?? 0;
            return totalValidDevices + getLocalizedString('import.review.device.totalFailed', ' devices will not be imported');
        } else if (employeeValidationDto) {
            const totalValidEmployees = employeeValidationDto?.invalidEmployeesCount ?? 0;
            return totalValidEmployees + getLocalizedString('import.review.employee.totalFailed', ' users will not be imported');
        }
        return undefined;
    }, [deviceValidationDto, employeeValidationDto, isDeviceImport])
    
    const validCount = React.useMemo(() => {
        if (isDeviceImport && deviceValidationDto) {
            return deviceValidationDto?.validDevices.length ?? 0;
        } else if (employeeValidationDto) {
            return employeeValidationDto?.validEmployees.length ?? 0;
        }
        return undefined;
    }, [deviceValidationDto, employeeValidationDto, isDeviceImport])
    
    const invalidCount = React.useMemo(() => {
        if (isDeviceImport && deviceValidationDto) {
            return deviceValidationDto?.invalidDevicesCount ?? 0;
        } else if (employeeValidationDto) {
            return employeeValidationDto?.invalidEmployeesCount ?? 0;
        }
        return undefined;
    }, [deviceValidationDto, employeeValidationDto, isDeviceImport])
    
    const showValidDropdown = React.useMemo(() => {
        if (deviceValidationDto) {
            if (deviceValidationDto?.validDevices.length) {
                return true;
            }
        } else if (employeeValidationDto) {
            if (employeeValidationDto?.validEmployees.length) {
                return true;
            }
        }
        return false;
    }, [deviceValidationDto, employeeValidationDto])
    
    const showInvalidDropdown = React.useMemo(() => {
        if (deviceValidationDto) {
            if (deviceValidationDto?.invalidDevicesCount) {
                return true;
            }
        } else if (employeeValidationDto) {
            if (employeeValidationDto?.invalidEmployeesCount) {
                return true;
            }
        }
    }, [deviceValidationDto, employeeValidationDto])
    
    return (
        <>
            <div className={'importuploadfile-root-div'}>
                <IconButton
                    id={`import-${isDeviceImport ? 'devices' : 'users'}-back-button`}
                    color="primary"
                    className={'back-button'}
                    aria-label="back"
                    onClick={() => {
                            getPreviousStatusAndNavigateToUploadFile()
                        }
                    }>
                    <ArrowBack className={'back-button-arrow'}/>
                </IconButton>
                <div className={'importuploadfile-container-div'}>
                    <FormTitle>
                        {getLocalizedString('import.review.title', 'Review ' + (isDeviceImport ? 'Device ' : 'User ') + 'Data', [isDeviceImport ? getLocalizedString('device', 'Device ') : getLocalizedString('employee', 'User ')])}
                    </FormTitle>
                    <div style={{marginTop: '32px', marginBottom: '32px'}}>
                        {!!validCount &&
                            <div className={'section-header'}>
                                <span className={'section-header'} style={{color: '#008651'}}>{validCount}</span>{isDeviceImport ? getLocalizedString('import.review.device.successCount', ' devices have no errors') : getLocalizedString('import.review.employee.successCount', ' users have no errors')}
                            </div>
                        }
                        {!!invalidCount &&
                            <div className={'section-header'}>
                                <span className={'section-header'} style={{color: 'red'}}>{invalidCount}</span>{isDeviceImport ? getLocalizedString('import.review.device.failCount', ' devices have errors') : getLocalizedString('import.review.employee.failCount', ' users have errors')}
                            </div>
                        }
                    </div>
                    {showValidDropdown &&
                        <SectionalDropdown dropdownTitle={validDropdownTitle} headerColor={'#C5F6E2'}
                                           sectionDtos={optionsSuccess}/>
                    }
                    {showInvalidDropdown &&
                        <SectionalDropdown dropdownTitle={invalidDropdownTitle} headerColor={'#FF00004D'}
                                           sectionDtos={optionsError}/>
                    }
                    {showInvalidDropdown ?
                        <div style={{display: 'flex', marginTop: '32px'}}>
                            <ARCButton
                                id={`import-${isDeviceImport ? 'devices' : 'users'}-reupload-button`}
                                fill={'filled'}
                                variant={'primary'}
                                size={'md'}
                                onClick={() => {
                                    getPreviousStatusAndNavigateToUploadFile()
                                }}
                                label={getLocalizedString('import.review.restart', 'Re-Upload File')}
                            >
                            </ARCButton>
                            <ARCTextLink
                                id={`import-${isDeviceImport ? 'devices' : 'users'}-skip-errors-button`}
                                variant={'primary'}
                                size={'md'}
                                label={getLocalizedString('import.review.skip', 'Skip Errors and import data')}
                                href={''}
                                onClick={() => {
                                    if (isDeviceImport) {
                                        dispatch(deviceContextActions.resetImportDevice());
                                    } else {
                                        dispatch(employeeContextActions.resetImportEmployee());
                                    }
                                    setCurrentChildComponent(ImportChildComponentNames.importUploadData)
                                }}>
                            </ARCTextLink>
                        </div>
                        :
                        <div style={{display: 'flex', marginTop: '32px'}}>
                            <ARCButton
                                id={`import-${isDeviceImport ? 'devices' : 'users'}-submit-button`}
                                fill={'filled'}
                                variant={'primary'}
                                size={'md'}
                                onClick={() => {
                                    setCurrentChildComponent(ImportChildComponentNames.importUploadData)
                                }}
                                label={getLocalizedString('import.review.import', 'Import Data')}
                            >
                            </ARCButton>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}
