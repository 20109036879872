import { Typography, styled } from '@material-ui/core';
import * as React from 'react';
import {calculateResponsiveSizeWithMinMax} from "../../util/TextUtils";
import CLIENT_PORTAL_ROLES from "../../assets/img/Client_Portal_Roles.png";
import KIOSK_ROLES from "../../assets/img/Kiosk_Roles.png";
import {getLocalizedString} from "../../util/Localization";

export type FaqSection = {
    id: string,
    sectionTitle: string,
    sectionContent: {
        sectionDescription: string,
        sectionSteps?: React.ReactNode[]
    },
    tipsSection?: React.ReactNode[]
}

export type FaqModule = {
    moduleTitle: string,
    moduleSections: FaqSection[]
}

export const KIOSK_ROLE_FAQ = getLocalizedString('faq.kioskSection.title', 'What are Kiosk Roles?')
export const CLIENT_PORTAL_ROLE_FAQ = getLocalizedString('faq.portalSection.title', 'What are Client Portal Roles?')

const SectionItem = styled(Typography)({
    fontSize: calculateResponsiveSizeWithMinMax('2', '18', '16'),
    marginBottom: '1vh'
});

const SubSectionItem = styled(Typography)({
    fontSize: calculateResponsiveSizeWithMinMax('2', '16', '14'),
    marginLeft: '4vw',
    marginBottom: '1vh'
});

const SubSection = styled('div') ({
    marginLeft: '2vw',
});

const ItemWithSubsection = styled('div') ({
    display: 'flex',
    flexDirection: 'column'
})

export const EMPLOYEE_FAQ_MODULE : FaqModule = {
    moduleTitle: getLocalizedString('faq.employees.title', 'Users'),
    moduleSections: [
        {
            id: 'faq-employees-add-employee-section',
            sectionTitle: getLocalizedString('faq.employees.addEmployee.title', 'How do I add an individual employee to the ARC Client Portal?'),
            sectionContent: {
                sectionDescription: '',
                sectionSteps: [<SectionItem>{getLocalizedString('faq.employees.addEmployee.step1', '1. Provide the employee with a')} <b>{getLocalizedString('faq.employees.addEmployee.step1.sub1', 'Badge ID')}</b></SectionItem>,
                    <SectionItem>{getLocalizedString('faq.employees.addEmployee.step2', '2. On the ARC Client Portal, navigate to ')}<b>{getLocalizedString('faq.employees.addEmployee.step2.sub1', 'Users')}</b>{getLocalizedString('faq.employees.addEmployee.step2.sub2', ' section.')}</SectionItem>,
                    <SectionItem>{getLocalizedString('faq.employees.addEmployee.step3', '3. Select ')}<b>{getLocalizedString('faq.employees.addEmployee.step3.sub1', 'Activate User')}</b>{getLocalizedString('faq.employees.addEmployee.step3.sub2', ' at the top of the page.')}</SectionItem>,
                    <ItemWithSubsection><SectionItem>{getLocalizedString('faq.employees.addEmployee.step4', '4. Enter ')}<b>{getLocalizedString('faq.employees.addEmployee.step4.sub1', 'First Name')}</b>, <b>{getLocalizedString('faq.employees.addEmployee.step4.sub2', 'Last Name')}</b>{getLocalizedString('faq.employees.addEmployee.step4.sub3', ', and ')}<b>{getLocalizedString('faq.employees.addEmployee.step4.sub4', 'Badge ID')}</b>.</SectionItem> <SubSectionItem>{getLocalizedString('faq.employees.addEmployee.step4.sub5', 'a.  Note: Middle Initial is an optional field to differentiate employees with the same names.')}</SubSectionItem></ItemWithSubsection>,
                    <SectionItem>{getLocalizedString('faq.employees.addEmployee.step5', '5. Assign the appropriate ')}<b>{getLocalizedString('faq.employees.addEmployee.step5.sub1', 'Kiosk Access Level.')}</b></SectionItem>,
                    <ItemWithSubsection><SectionItem>{getLocalizedString('faq.employees.addEmployee.step6', '6. Assign the appropriate ')}<b>{getLocalizedString('faq.employees.addEmployee.step6.sub1', 'Portal Access Level')}</b>.</SectionItem></ItemWithSubsection>,
                    <SectionItem>{getLocalizedString('faq.employees.addEmployee.step7', '7. Assign the appropriate ')}<b>{getLocalizedString('faq.employees.addEmployee.step7.sub1', 'Location Access')}</b>.</SectionItem>,
                    <SectionItem>{getLocalizedString('faq.employees.addEmployee.step8', '8. Click ')}<b>{getLocalizedString('faq.employees.addEmployee.step8.sub1', 'Save.')}</b></SectionItem>]
            }
        },
        {
            id: 'faq-employees-bulkadd-employees-section',
            sectionTitle: getLocalizedString('faq.employees.bulkAdd.title', 'How do I bulk-add employees to the ARC Client Portal?'),
            sectionContent: {
                sectionDescription: '',
                sectionSteps: [
                    <ItemWithSubsection><SectionItem>{getLocalizedString('faq.employees.bulkAdd.step1', '1. In the ')}<b>{getLocalizedString('faq.employees.bulkAdd.step1.sub1', 'Users')}</b>{getLocalizedString('faq.employees.bulkAdd.step1.sub2', ' section, select ')}<b>{getLocalizedString('faq.employees.bulkAdd.step1.sub3', 'Import Users')}</b>{getLocalizedString('faq.employees.bulkAdd.step1.sub4', ' at the top of the page.')}</SectionItem>
                        <SubSectionItem>{getLocalizedString('faq.employees.bulkAdd.step1.sub5', 'a. Note: If you have access to multiple locations, you must select one location.')}</SubSectionItem>
                    </ItemWithSubsection>,
                    <SectionItem>{getLocalizedString('faq.employees.bulkAdd.step2', '2. Select ')}<b>{getLocalizedString('faq.employees.bulkAdd.step2.sub1', 'Download Template')}</b>{getLocalizedString('faq.employees.bulkAdd.step2.sub2', '. A CSV template will download to your computer. We recommend saving this to your desktop.')}</SectionItem>,
                    <SectionItem>{getLocalizedString('faq.employees.bulkAdd.step3', '3. Open the CSV. Starting in row 3, fill in columns ')}<b>{getLocalizedString('faq.employees.bulkAdd.step3.sub1', '(A) First Name, (C) Last Name,')}</b>{getLocalizedString('faq.employees.bulkAdd.step3.sub2', ' and ')}<b>{getLocalizedString('faq.employees.bulkAdd.step3.sub3', '(E) Badge ID.')}</b>{getLocalizedString('faq.employees.bulkAdd.step3.sub4', ' Middle Initial is optional.')}</SectionItem>,
                    <SectionItem>{getLocalizedString('faq.employees.bulkAdd.step4', '4. Save the file. Then, drag and drop the file into the gray ')}<b>{getLocalizedString('faq.employees.bulkAdd.step4.sub1', 'drag file here')}</b>{getLocalizedString('faq.employees.bulkAdd.step4.sub2', ' box in the portal. The portal will display the number of users added to the ARC system.')}</SectionItem>
                ]
            },
            tipsSection: [
                <SectionItem>{getLocalizedString('faq.employees.bulkAdd.tips.1', 'Row 2 gives samples of accepted inputs. Start filling in names in row 3.')}</SectionItem>,
                <SectionItem>{getLocalizedString('faq.employees.bulkAdd.tips.2', 'You can use the same CSV file to continue to add names and badges, even if the file has already been uploaded. The system will add new users and skip those that have already been uploaded into the system.')}</SectionItem>,
            ]
        },
        {
            id: 'faq-employees-remove-section',
            sectionTitle: getLocalizedString('faq.employees.remove.title', 'How do I remove an employee from the ARC Client Portal?'),
            sectionContent: {
                sectionDescription: '',
                sectionSteps: [
                    <SectionItem>{getLocalizedString('faq.employees.remove.step1', '1. On the ARC Client Portal, navigate to the ')}<b>{getLocalizedString('faq.employees.remove.step1.sub1', 'Users')}</b>{getLocalizedString('faq.employees.remove.step1.sub2', ' section.')}</SectionItem>,
                    <SectionItem>{getLocalizedString('faq.employees.remove.step2', '2. Search or scroll to the user to be removed and click ')}<b>{getLocalizedString('faq.employees.remove.step2.sub1', 'Edit.')}</b></SectionItem>,
                    <SectionItem>{getLocalizedString('faq.employees.remove.step3', '3. Scroll down to ')}<b>{getLocalizedString('faq.employees.remove.step3.sub1', 'Location Access.')}</b></SectionItem>,
                    <SectionItem>{getLocalizedString('faq.employees.remove.step4', '4. Uncheck the Location(s) where you wish to deactivate the user.')}</SectionItem>,
                    <SectionItem>{getLocalizedString('faq.employees.remove.step5', '5. Click ')}<b>{getLocalizedString('faq.employees.remove.step5.sub1', 'Save.')}</b></SectionItem>
                ]
            }
        },
        {
            id: 'faq-employees-updatebadge-section',
            sectionTitle: getLocalizedString('faq.employees.updateBadge.title', 'How do I update/replace an employee Badge ID?'),
            sectionContent: {
                sectionDescription: '',
                sectionSteps: [
                    <SectionItem>{getLocalizedString('faq.employees.updateBadge.step1', '1. On the ARC Client Portal, navigate to the ')}<b>{getLocalizedString('faq.employees.updateBadge.step1.sub1', 'Users')}</b>{getLocalizedString('faq.employees.updateBadge.step1.sub2', ' section.')}</SectionItem>,
                    <SectionItem>{getLocalizedString('faq.employees.updateBadge.step2', '2. Next to the user\'s name, click ')}<b>{getLocalizedString('faq.employees.updateBadge.step2.sub1', 'Edit.')}</b></SectionItem>,
                    <SectionItem>{getLocalizedString('faq.employees.updateBadge.step3', '3. On the ')}<b>{getLocalizedString('faq.employees.updateBadge.step3.sub1', 'Edit User')}</b>{getLocalizedString('faq.employees.updateBadge.step3.sub2', ' page, next to Badge ID, click ')}<b>{getLocalizedString('faq.employees.updateBadge.step3.sub3', 'Edit.')}</b></SectionItem>,
                    <SectionItem>{getLocalizedString('faq.employees.updateBadge.step4', '4. Click ')}<b>{getLocalizedString('faq.employees.updateBadge.step4.sub1', 'Save.')}</b></SectionItem>
                ]
            }
        },
        {
            id: 'faq-employees-updatepassword-section',
            sectionTitle: getLocalizedString('faq.employees.updatePassword.title', 'How do I reset my password in the ARC Client Portal?'),
            sectionContent: {
                sectionDescription: '',
                sectionSteps: [
                    <SectionItem>{getLocalizedString('faq.employees.updatePassword.subtitle', 'There are two ways to reset your password.')}</SectionItem>,
                    <SectionItem>{getLocalizedString('faq.employees.updatePassword.step1', 'Method 1')}</SectionItem>,
                    <SubSection>
                        <SectionItem>{getLocalizedString('faq.employees.updatePassword.step1.sub1', '1. Navigate to your initials inside the circle icon on the top right of the page.')}</SectionItem>
                        <SectionItem>{getLocalizedString('faq.employees.updatePassword.step1.sub2', '2. Click the circle and select ')}<b>{getLocalizedString('faq.employees.updatePassword.sub2.1', 'Change Password.')}</b></SectionItem>
                    </SubSection>,
                    <SectionItem>{getLocalizedString('faq.employees.updatePassword.step2', 'Method 2')}</SectionItem>,
                    <SubSection>
                        <SectionItem>{getLocalizedString('faq.employees.updatePassword.step2.sub1', '1. On the Client Portal login page, click')}<b>{getLocalizedString('faq.employees.updatePassword.sub1.1', ' Forgot Password.')}</b></SectionItem>
                        <SectionItem>{getLocalizedString('faq.employees.updatePassword.step2.sub2', '2. Follow the onscreen instruction.')}</SectionItem>
                    </SubSection>
                ]
            }
        },
        {
            id: 'faq-employees-pickup-permission-section',
            sectionTitle: getLocalizedString('faq.employees.devicePickupPermission.title', 'How do I assign device permissions to users in bulk?'),
            sectionContent: {
                sectionDescription: '',
                sectionSteps: [
                    <SectionItem>{getLocalizedString('faq.employees.devicePickupPermission.subtitle', 'If your location uses multiple types of devices, the Device Permissions Groups feature is a useful way to apply a Device Pick Up Permission to many users.')}</SectionItem>,
                    <SectionItem>{getLocalizedString('faq.employees.devicePickupPermission.step1', '1. In the top right of your screen, select the ')}<b>{getLocalizedString('faq.employees.devicePickupPermission.step1.sub1', 'Users')}</b>{getLocalizedString('faq.employees.devicePickupPermission.step1.sub2', ' tab.')}</SectionItem>,
                    <ItemWithSubsection><SectionItem>{getLocalizedString('faq.employees.devicePickupPermission.step2', '2. Click the ')}<b>{getLocalizedString('faq.employees.devicePickupPermission.step2.sub1', 'Users')}</b>{getLocalizedString('faq.employees.devicePickupPermission.step2.sub2', ' tab again to open the Users Dropdown, and select ')}<b>{getLocalizedString('faq.employees.devicePickupPermission.step2.sub3', 'Device Permissions Groups.')}</b></SectionItem>
                        <SubSectionItem>{getLocalizedString('faq.employees.devicePickupPermission.step2.sub4', 'a. Note: If you have access to multiple locations, you must select one location.')}</SubSectionItem>
                    </ItemWithSubsection>,
                    <SectionItem>{getLocalizedString('faq.employees.devicePickupPermission.step3', '3. On the Device Permissions Groups page, click ')}<b>{getLocalizedString('faq.employees.devicePickupPermission.step3.sub1', 'Create New Group.')}</b></SectionItem>,
                    <ItemWithSubsection><SectionItem>{getLocalizedString('faq.employees.devicePickupPermission.step4', '4. Enter the ')}<b>{getLocalizedString('faq.employees.devicePickupPermission.step4.sub1', 'Group Name,')}</b>{getLocalizedString('faq.employees.devicePickupPermission.step4.sub2', ' and select the appropriate ')}<b>{getLocalizedString('faq.employees.devicePickupPermission.step4.sub3', 'Device Permissions.')}</b></SectionItem>
                        <SubSectionItem>{getLocalizedString('faq.employees.devicePickupPermission.step4.sub4', 'a. Note: Read the Configuration Summary to double-check that the intended permissions are correct.')}</SubSectionItem>
                    </ItemWithSubsection>,
                    <SectionItem>{getLocalizedString('faq.employees.devicePickupPermission.step5', '5. Click ')}<b>{getLocalizedString('faq.employees.devicePickupPermission.step5.sub1', 'Save.')}</b></SectionItem>,
                    <SectionItem>{getLocalizedString('faq.employees.devicePickupPermission.step6', '6. You have successfully saved the Device Permissions group. Now, you can apply these settings to the desired Users.')}</SectionItem>,
                    <SectionItem>{getLocalizedString('faq.employees.devicePickupPermission.step7', '7. Navigate to the ')}<b>{getLocalizedString('faq.employees.devicePickupPermission.step7.sub1', 'Users')}</b>{getLocalizedString('faq.employees.devicePickupPermission.step7.sub2', ' page.')}</SectionItem>,
                    <SectionItem>{getLocalizedString('faq.employees.devicePickupPermission.step8', '8. Select the checkbox next to the User(s) you would like to apply these device permissions to.')}</SectionItem>,
                    <SectionItem>{getLocalizedString('faq.employees.devicePickupPermission.step9', '9. In the ')}<b>{getLocalizedString('faq.employees.devicePickupPermission.step9.sub1', 'Bulk Task')}</b>{getLocalizedString('faq.employees.devicePickupPermission.step9.sub2', ' dropdown at the top of the page, select ')}<b>{getLocalizedString('faq.employees.devicePickupPermission.step9.sub3', 'Change Device Permissions Group')}</b>{getLocalizedString('faq.employees.devicePickupPermission.step9.sub4', ', then ')}<b>{getLocalizedString('faq.employees.devicePickupPermission.step9.sub5', 'Submit Bulk Task.')}</b></SectionItem>,
                ]
            }
        },
        {
            id: 'faq-employees-kiosk-group-section',
            sectionTitle: KIOSK_ROLE_FAQ,
            sectionContent: {
                sectionDescription: '',
                sectionSteps: [
                    <SectionItem>{getLocalizedString('faq.employees.kioskGroup.subtitle', 'A user’s Kiosk Role defines what permissions and level of access they have at the ARC Kiosk. See the chart below for a breakdown of the different Kiosk Roles.')}</SectionItem>,
                    <SectionItem>
                        <img 
                            style={{
                                width: calculateResponsiveSizeWithMinMax('45', '660', '200'), 
                                height: calculateResponsiveSizeWithMinMax('45', '660', '200')
                            }}
                            src={KIOSK_ROLES} 
                            alt={'Kiosk Roles'}
                        />
                    </SectionItem>,
                ]
            }
        },
        {
            id: 'faq-employees-portal-group-section',
            sectionTitle: CLIENT_PORTAL_ROLE_FAQ,
            sectionContent: {
                sectionDescription: '',
                sectionSteps: [
                    <SectionItem>{getLocalizedString('faq.employees.portalGroup.subtitle', 'A user’s Client Portal Role defines what permissions and level of access they have on the ARC Client Portal. See the chart below for a breakdown of the different Client Portal Roles.')}</SectionItem>,
                    <SectionItem>
                        <img 
                            style={{
                                width: calculateResponsiveSizeWithMinMax('45', '660', '200'), 
                                height: calculateResponsiveSizeWithMinMax('35', '510', '150')
                            }} 
                            src={CLIENT_PORTAL_ROLES} 
                            alt={'Client Portal Roles'}
                        />
                    </SectionItem>,
                ]
            }
        }
    ]
}

export const DEVICE_FAQ_MODULE : FaqModule = {
    moduleTitle: getLocalizedString('faq.device.title', 'Devices'),
    moduleSections: [
        {
            id: 'faq-devices-add-device-section',
            sectionTitle: getLocalizedString('faq.device.addDevice.title', 'How do I register an individual device to the ARC Client Portal?'),
            sectionContent: {
                sectionDescription: '',
                sectionSteps: [
                    <SectionItem>{getLocalizedString('faq.device.addDevice.step1', '1. Assign a ')}<b>{getLocalizedString('faq.device.addDevice.step1.sub1', 'Device ID')}</b>{getLocalizedString('faq.device.addDevice.step1.sub2', ' to the device.')}</SectionItem>,
                    <SectionItem>{getLocalizedString('faq.device.addDevice.step2', '2. On the ARC Client Portal, navigate to the ')}<b>{getLocalizedString('faq.device.addDevice.step2.sub1', 'Devices')}</b>{getLocalizedString('faq.device.addDevice.step2.sub2', ' section.')}</SectionItem>,
                    <SectionItem>{getLocalizedString('faq.device.addDevice.step3', '3. Select ')}<b>{getLocalizedString('faq.device.addDevice.step3.sub1', 'Register New Device')}</b>{getLocalizedString('faq.device.addDevice.step3.sub2', ' at the top of the page.')}</SectionItem>,
                    <SectionItem>{getLocalizedString('faq.device.addDevice.step4', '4. Enter ')}<b>{getLocalizedString('faq.device.addDevice.step4.sub1', 'Device ID')}</b>{getLocalizedString('faq.device.addDevice.step4.sub2',' and ')}<b>{getLocalizedString('faq.device.addDevice.step4.sub3', 'Serial Number.')}</b></SectionItem>,
                    <SectionItem>{getLocalizedString('faq.device.addDevice.step5', '5. Select Device Type')}</SectionItem>,
                    <SectionItem>{getLocalizedString('faq.device.addDevice.step6', '6. Click ')}<b>{getLocalizedString('faq.device.addDevice.step6.sub1', 'Save')}</b>.</SectionItem>,
                    <SectionItem>{getLocalizedString('faq.device.addDevice.step7', '7. This device is now ready to be activated at a kiosk.')}</SectionItem>,
                ]
            },
            tipsSection:  [
                <SectionItem>{getLocalizedString('faq.device.addDevice.tip.1', 'New devices are recognized in the ARC system within 2 minutes.')}</SectionItem>,
            ]
        },
        {
            id: 'faq-device-bulkadd-devices-section',
            sectionTitle: getLocalizedString('faq.device.bulkAdd.title', 'How do I bulk-add devices to the ARC Client Portal?'),
            sectionContent: {
                sectionDescription: '',
                sectionSteps: [
                    <SectionItem>{getLocalizedString('faq.device.bulkAdd.step1', '1. On the ARC Client Portal, navigate to the ')}<b>{getLocalizedString('faq.device.bulkAdd.step1.sub1', 'Devices')}</b>{getLocalizedString('faq.device.bulkAdd.step1.sub2', ' section.')}</SectionItem>,
                    <SectionItem>{getLocalizedString('faq.device.bulkAdd.step2', '2. Click the Devices section again to open the Devices dropdown and select ')}<b>{getLocalizedString('faq.device.bulkAdd.step2.sub1', 'Import Devices.')}</b></SectionItem>,
                    <SectionItem>{getLocalizedString('faq.device.bulkAdd.step3', '3. Select Download Template. A CSV template will download to your computer. We recommend saving this to your desktop.')}</SectionItem>,
                    <SectionItem>{getLocalizedString('faq.device.bulkAdd.step4', '4. Open the CSV. Starting in row 2, fill in columns ')}<b>{getLocalizedString('faq.device.bulkAdd.step4.sub1', '(A) Serial Number, (B) Device ID (QR Code), and (C) Device Type')}</b>{getLocalizedString('faq.device.bulkAdd.step4.sub2', ', if applicable.')}</SectionItem>,
                    <SectionItem>{getLocalizedString('faq.device.bulkAdd.step5', '5. Save the file. Then, drag and drop the file into the gray ')}<b>{getLocalizedString('faq.device.bulkAdd.step5.sub1', 'drag file here')}</b>{getLocalizedString('faq.device.bulkAdd.step5.sub2', ' box in the portal. The portal will display the number of devices added to the ARC system.')}</SectionItem>
                ]
            },
            tipsSection: [
                <SectionItem>{getLocalizedString('faq.device.bulkAdd.tip.1', 'You can use the same CSV file to continue to add devices, even if the file has already been uploaded. The system will add new devices and skip any that have already been uploaded into the system.')}</SectionItem>
            ]
        },
        {
            id: 'faq-devices-deactivate-section',
            sectionTitle: getLocalizedString('faq.device.deactivate.title', 'How do I deactivate a device from the ARC Client Portal if it is being sent out for service?'),
            sectionContent: {
                sectionDescription: '',
                sectionSteps: [
                    <SectionItem>{getLocalizedString('faq.device.deactivate.step1', '1. On the ARC Client Portal, navigate to the ')}<b>{getLocalizedString('faq.device.deactivate.step1.sub1', 'Devices')}</b>{getLocalizedString('faq.device.deactivate.step1.sub2', ' section.')}</SectionItem>,
                    <SectionItem>{getLocalizedString('faq.device.deactivate.step2', '2. Search or scroll to the device to be removed and click the three dots, then select ')}<b>{getLocalizedString('faq.device.deactivate.step2.sub1', 'Deactivate.')}</b></SectionItem>,
                    <SectionItem>{getLocalizedString('faq.device.deactivate.step3', '3. Select the reason for deactivation.')}</SectionItem>,
                    <SectionItem>{getLocalizedString('faq.device.deactivate.step4', '4. Click ')}<b>{getLocalizedString('faq.device.deactivate.step4.sub1', 'Save.')}</b></SectionItem>,
                ]
            },
            tipsSection: [
                <SectionItem>{getLocalizedString('faq.device.deactivate.tip.1', 'Once a device has been marked as "RMA", immediately open a ticket per your usual process.')}</SectionItem>,
            ]
        },
        {
            id: 'faq-devices-update-deviceid-section',
            sectionTitle: getLocalizedString('faq.device.updateIdNumber.title', 'How do I update/replace a Device ID?'),
            sectionContent: {
                sectionDescription: '',
                sectionSteps: [
                    <SectionItem>{getLocalizedString('faq.device.updateIdNumber.step1', '1. On the ARC Client Portal, navigate to the ')}<b>{getLocalizedString('faq.device.updateIdNumber.step1.sub1', 'Devices')}</b>{getLocalizedString('faq.device.updateIdNumber.step1.sub2',' section.')}</SectionItem>,
                    <SectionItem>{getLocalizedString('faq.device.updateIdNumber.step2', '2. Search or scroll to the device that requires a new code. Click the three dots icon, then select ')}<b>{getLocalizedString('faq.device.updateIdNumber.step2.sub1', 'Edit')}</b>.</SectionItem>,
                    <SectionItem>{getLocalizedString('faq.device.updateIdNumber.step3', '3. Enter the new device QR code number.')}</SectionItem>,
                    <SectionItem>{getLocalizedString('faq.device.updateIdNumber.step4', '4. Click ')}<b>{getLocalizedString('faq.device.updateIdNumber.step4.sub1', 'Save.')}</b></SectionItem>,
                ]
            }
        }
    ]
};

export const DEVICE_LIMIT_FAQ_MODULE : FaqModule = {
    moduleTitle: getLocalizedString('faq.activeDeviceLimit.title', 'Active Device Limit'),
    moduleSections: [
        {
            id: 'faq-devices-active-device-limit-activate-more-section',
            sectionTitle: getLocalizedString('faq.activeDeviceLimit.activateMore.title', 'Can I activate more devices?'),
            sectionContent: {
                sectionDescription: '',
                sectionSteps: [
                    <SectionItem>{getLocalizedString('faq.activeDeviceLimit.activateMore.step1', 'You can activate more devices if your device count is under the Active Device Limit. If your Active Device Limit has been reached, you must remove and deactivate one or more devices before activating additional devices.')}</SectionItem>,
                ]
            }
        },
        {
            id: 'faq-devices-active-device-limit-how-section',
            sectionTitle: getLocalizedString('faq.activeDeviceLimit.how.title', 'How does the Active Device Limit work?'),
            sectionContent: {
                sectionDescription: '',
                sectionSteps: [
                    <SectionItem>{getLocalizedString('faq.activeDeviceLimit.how.step1', 'The location has a maximum number of Devices that can be active at any given time across all ARC kiosks. Once the maximum Active Devices is reached, a manager must remove and deactivate devices before adding new ones.')}</SectionItem>
                ]
            }
        },
        {
            id: 'faq-devices-active-device-limit-why-section',
            sectionTitle: getLocalizedString('faq.activeDeviceLimit.why.title', 'Why is there an Active Device Limit?'),
            sectionContent: {
                sectionDescription: '',
                sectionSteps: [
                    <SectionItem>{getLocalizedString('faq.activeDeviceLimit.why.step1', 'The Active Device Limit ensures that a few extra lockers are reserved at each location to account for out of service lockers. This helps ensure that users can always return their devices to an available locker.')}</SectionItem>
                ]
            }
        }
    ]
};
