import React from 'react';
import '../../../css/DevicePickupPermissions.css';
import {DevicePickupPermissionDeviceTypeDto, DevicePickupPermissionDto} from "../../../dtos/DevicePickupPermission";
import {DeviceTypeDto} from "../../../dtos/DeviceType";
import {Delete} from "@material-ui/icons";
import {useTheme} from "@material-ui/core";
import {EmployeeDto} from "../../../dtos/Employee";
import Toggle from "../../ui-components/Toggle";
import {Option} from '../../ui-components/ColorSelect';
import {getLocalizedString} from "../../../util/Localization";
import {ARCTextInput} from "../../ui-components/design-system/ARCTextInput";
import {ARCSelectInput} from "../../ui-components/design-system/ARCSelectInput";

type DevicePickupPermissionInputContainerProps = {
    permission: DevicePickupPermissionDto,
    deviceTypes: DeviceTypeDto[],
    allowMultipleCheckoutsToggle: boolean,
    configHideable?: boolean,
    employee?: EmployeeDto,
    hideSummary?: boolean,
    onChange: (updatedData: DevicePickupPermissionDto) => void;
};

const DevicePickupPermissionInputContainer: React.FC<DevicePickupPermissionInputContainerProps> = ({ permission, deviceTypes, allowMultipleCheckoutsToggle, configHideable, employee, hideSummary, onChange}) => {
    const theme = useTheme();

    const FormPermissions = React.useMemo(() => {
        return {
            fields: {
                allowMultipleCheckouts: {
                    display: allowMultipleCheckoutsToggle
                },
                deviceTypes: {
                    display: deviceTypes.length >= 1
                }
            }
        }
    }, [allowMultipleCheckoutsToggle, deviceTypes.length]);

    const DeviceTypeOptions = React.useMemo(() => {
        let options : Option[] = deviceTypes.slice().filter(type => !permission.deviceTypes.find(dt => dt.deviceTypeId === type.deviceTypeId)).map(dt => {
            return {
                value: dt.deviceTypeId,
                label: dt.deviceTypeName
            }
        });

        return options;
    }, [deviceTypes, permission.deviceTypes]);

    const permissionLabel = React.useMemo(() => {
        let permLabel = '';

        if(employee?.firstName && permission.custom) {
            permLabel = employee.firstName;
        } else {
            permLabel = permission.permissionName + ' ' + getLocalizedString('employee.devicePickupPermission.employees', 'Users');
        }

        return permLabel;
    }, [employee?.firstName, permission.custom, permission.permissionName]);

    const [showConfiguration, setShowConfiguration] = React.useState<boolean>(false);

    const handleToggleAllowMultipleCheckouts = () => {
        const deviceTypesReset = permission.deviceTypes.slice().map(dt => {return {...dt, quantity: 1}});

        onChange({...permission, deviceTypes: deviceTypesReset, allowMultipleCheckouts: !permission.allowMultipleCheckouts});
    }

    const handleAddDeviceType = () => {
        onChange({...permission, deviceTypes: [...permission.deviceTypes, {devicePickupPermissionDeviceTypeId: undefined, deviceTypeId: undefined, quantity: 1 }]});
    };

    const handleDeleteDeviceType = (index: number) => {
        const newDeviceTypes = [...permission.deviceTypes];
        newDeviceTypes.splice(index, 1);

        onChange({...permission, deviceTypes: newDeviceTypes});
    };

    const handleUpdateDeviceType = (index: number, updatedDeviceType: DevicePickupPermissionDeviceTypeDto) => {
        const newDeviceTypes = [...permission.deviceTypes];
        newDeviceTypes[index] = updatedDeviceType;

        onChange({...permission, deviceTypes: newDeviceTypes});
    };

    React.useEffect(() => {
        setShowConfiguration(!configHideable);
    }, [configHideable]);

    return (
        <div className={`permission-container ${employee && 'permission-container-indented'}`}>
            {!employee &&
            <div style={{width: '20vw', marginBottom: '40px'}}>
                <ARCTextInput 
                    id={'pickup-permission-name-input'}
                    size={'md'}
                    required={true} 
                    value={permission.permissionName}
                    label={getLocalizedString('employee.devicePickupPermission.permissionName', 'Group Name')}
                    onChange={(e) => onChange({...permission, permissionName: e})}
                />
            </div>
            }

            {showConfiguration &&
                <>
                    {FormPermissions.fields.allowMultipleCheckouts.display &&
                    <>
                        <label className={'permission-section-header'} style={{marginTop: '0'}}>{getLocalizedString('employee.devicePickupPermission.permissionConfig', 'Pick Up Configuration:')}<span className={'required-star'}>{'*'}</span></label>

                        <div className={'devicetype-row'} style={{flexWrap: 'wrap'}}>
                            <label>{getLocalizedString('employee.devicePickupPermission.permissionConfigTitle', 'Users can pick up')}</label>
                            <Toggle
                                   className={'permission-toggle'}
                                   offText={getLocalizedString('employee.devicePickupPermission.allowMultipleCheckouts.false', 'one device')}
                                   onText={getLocalizedString('employee.devicePickupPermission.allowMultipleCheckouts.true', 'multiple devices')}
                                   on={permission.allowMultipleCheckouts}
                                   onChange={handleToggleAllowMultipleCheckouts}/>

                            <label>{getLocalizedString('employee.devicePickupPermission.allowMultipleCheckouts.simultaneous', 'at the same time')}</label>
                        </div>
                    </>
                    }

                    <label className={'permission-section-header'}>{getLocalizedString('employee.devicePickupPermission.permissions', 'Device Permissions:')}<span className={'required-star'}>{'*'}</span></label>

                    <div className={'devicetype-row'}>
                        {FormPermissions.fields.deviceTypes.display && deviceTypes.length > 1 &&
                            <label className={'permission-input permission-input-label'}>{getLocalizedString('employee.devicePickupPermission.deviceTypes', 'Device Types')}</label>
                        }

                        <label className={'permission-input permission-input-label'}>{getLocalizedString('employee.devicePickupPermission.quantity', 'Quantity')}</label>
                    </div>


                    {FormPermissions.fields.deviceTypes.display ?
                        permission.deviceTypes.map((deviceType, index) => (
                        (!deviceType.deviceTypeId || (deviceTypes.find(dt => dt.deviceTypeId === deviceType.deviceTypeId))) &&
                        <div key={index} className={'devicetype-row'}>
                            {FormPermissions.fields.deviceTypes.display && deviceTypes.length > 1 &&
                                <ARCSelectInput
                                    id={'pickup-permission-select-device-type-input'}
                                    size={'md'}
                                    className={'permission-input permission-select'}
                                    value={deviceType.deviceTypeId}
                                    onChange={(option) => handleUpdateDeviceType(index, {
                                        ...deviceType,
                                        deviceTypeId: parseInt(option.value)
                                    })}
                                    options={DeviceTypeOptions}
                                    placeholder={deviceTypes.find(dt => dt.deviceTypeId === deviceType.deviceTypeId)?.deviceTypeName ?? getLocalizedString('employee.devicePickupPermission.selectDeviceType', 'Select Device Type')}
                                />
                            }


                            {permission.allowMultipleCheckouts ?
                                <div className={'permission-input-quantity'}>
                                    <button id={'pickup-permission-quantity-minus-button'} className={`permission-quantity-button ${deviceType.quantity <= 1 ? 'disabled' : ''}`} type='button' disabled={deviceType.quantity <= 1} onClick={() => handleUpdateDeviceType(index, {...deviceType, quantity: deviceType.quantity - 1})}>-</button>
                                    <span className={'permission-quantity'}>{deviceType.quantity}</span>
                                    <button id={'pickup-permission-quantity-plus-button'} className={`permission-quantity-button ${deviceType.quantity >= 3 ? 'disabled' : ''}`} type='button' disabled={deviceType.quantity >= 3} onClick={() => handleUpdateDeviceType(index, {...deviceType, quantity: deviceType.quantity + 1})}>+</button>
                                </div>
                                :
                                <div className={'permission-input-quantity'}>
                                    <span className={'permission-quantity'}>{deviceType.quantity}</span>
                                </div>
                            }

                            {FormPermissions.fields.deviceTypes.display && deviceTypes.length === 1 &&
                            <span className={'permission-input permission-select permission-view-only'}>{deviceTypes[0].deviceTypeName}</span>
                            }


                            {FormPermissions.fields.deviceTypes.display && permission.deviceTypes.length > 1 &&
                                <Delete style={{cursor: 'pointer', marginLeft: '10px'}}
                                        onClick={() => handleDeleteDeviceType(index)}>Delete</Delete>
                            }
                        </div>
                    ))
                    :
                        <div className={'devicetype-row'}>
                            <div className={'permission-input-quantity'}>
                                <span className={'permission-quantity'}>{'1'}</span>
                            </div>
                        </div>
                    }

                    {FormPermissions.fields.deviceTypes.display && deviceTypes.length > 1 &&
                    <div className={'permission-input'}
                         style={{display: 'flex', cursor: 'pointer', color: theme.palette.primary.main}}
                         onClick={handleAddDeviceType}
                         id={'pickup-permission-add-device-type-button'}>
                        <button style={{
                            marginLeft: '0',
                            color: theme.palette.primary.main,
                            borderColor: theme.palette.primary.main
                        }} className={'permission-quantity-button'}>
                            +
                        </button>

                        <div className={'permission-input'}>{getLocalizedString('employee.devicePickupPermission.addDeviceType', 'Add Device Type')}</div>
                    </div>
                    }
                </>
            }

            {!hideSummary &&
                <>
                    <div style={{display: 'flex', alignItems: 'baseline'}}>
                        <label className={'permission-section-header'}>{getLocalizedString('employee.devicePickupPermission.permissionConfigSummary', 'Configuration Summary:')}</label>

                        {!!configHideable &&
                            <div style={{marginLeft: '20px'}}>
                                {
                                    showConfiguration ?
                                        <div id={'pickup-permission-hide-configuration-button'} className={'Link'} onClick={() => setShowConfiguration(false)}>{getLocalizedString('employee.devicePickupPermission.hideDetails', 'Hide Details')}</div>
                                        :
                                        <div id={'pickup-permission-show-configuration-button'} className={'Link'} onClick={() => setShowConfiguration(true)}>{getLocalizedString('employee.devicePickupPermission.edit', 'Edit Configuration')}</div>
                                }
                            </div>
                        }
                    </div>

                    {FormPermissions.fields.deviceTypes.display && deviceTypes.length > 0 ?
                        <>
                            {permission.deviceTypes.filter(pdt => !!pdt.deviceTypeId).length === 0 ? (
                                <label>{getLocalizedString('employee.devicePickupPermission.pleaseSelectDeviceType', 'Please Select a Device Type')}</label>
                            ) : (
                                <>
                                    <label className={'summary-text'}>{getLocalizedString('employee.devicePickupPermission.summary', permissionLabel + ' can pick up ', [permissionLabel])}</label>

                                    <ul style={{margin: 'unset'}}>
                                        {
                                            permission.deviceTypes.map((deviceType, index) => (
                                                !!deviceType.deviceTypeId && deviceTypes.find(dt => dt.deviceTypeId === deviceType.deviceTypeId) &&
                                                <li className={'summary-text'} key={index}>
                                                    {deviceType.quantity + 'x '}
                                                    <b style={{fontWeight: 'bold'}}>{deviceTypes.find(dt => dt.deviceTypeId === deviceType.deviceTypeId)?.deviceTypeName + ' '}</b>

                                                    {index + 1 < permission.deviceTypes.filter(dtFilter => !!dtFilter.deviceTypeId && deviceTypes.find(dt => dt.deviceTypeId === dtFilter.deviceTypeId)).length &&
                                                        <>
                                                            {permission.allowMultipleCheckouts ?  getLocalizedString('and', 'and') : getLocalizedString('or', 'or')}
                                                        </>
                                                    }
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </>
                            )}
                        </>
                        :
                        <label>{getLocalizedString('employee.devicePickupPermission.summary', permissionLabel + ' can pickup', [permissionLabel]) + ' ' + (permission.deviceTypes[0]?.quantity ?? '1') + ' ' + (permission.deviceTypes[0]?.quantity > 1 ? getLocalizedString('employee.devicePickupPermission.devices', 'devices') : getLocalizedString('employee.devicePickupPermission.device', 'device'))}</label>
                    }
                </>
            }
        </div>
    );
};

export default DevicePickupPermissionInputContainer;
