import * as React from 'react';
import {useAppDispatch, useAppSelector} from "../../../../store/hooks";
import {IconButton} from "@material-ui/core";
import {appContextSelector, sortedDeviceIssuesSelector} from "../../../app-context/appContextSlice";
import {
    deactivateDevice,
    deviceContextActions,
    deviceContextSelector,
    getDeviceForEdit,
    getDeviceStats, updateDuplicateList
} from "../../deviceSlice";
import {
    DEV_STATUS_INACTIVE,
    DeviceDto,
    DeviceStatDto,
    INACTIVE_REASON_NONFUNCTIONAL,
    INACTIVE_REASONS
} from "../../../../dtos/Device";
import AppBar from "../../../appbar/AppBar";
import {SideBar} from "../../../sidebar/SideBar";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {AppWithFooter} from "../../../../App";
import {
    ErrorMessageSectionItem,
} from "../../../ui-components/StyledComponents";
import {selectLocationThunk} from "../../../app-context/thunks/SelectLocationThunk";
import {LocationState} from "../../../../@types/types";
import {ServerMessage} from "../../../ui-components/ServerMessage";
import {ArrowBack} from "@material-ui/icons";
import {DeactivateDeviceNotes} from "./DeactivateDeviceNotes";
import {getLocalizedString} from "../../../../util/Localization";
import {ARCButton} from "../../../ui-components/design-system/ARCButton";
import {ARCCheckbox} from "../../../ui-components/design-system/ARCCheckbox";
import {ARCRadioInput} from "../../../ui-components/design-system/ARCRadioInput";
import "./DeactivateDevice.scss"


const useAutoSelectLocationByUrl = () => {
    const context = useAppSelector(appContextSelector);
    const dispatch = useAppDispatch();
    const {locationId, deviceId} = useParams();

    React.useEffect(() => {
        if(locationId && context.currentLocation?.locationId !== parseInt(locationId)) {
            dispatch(selectLocationThunk(parseInt(locationId)));

            if(deviceId) {
                dispatch(getDeviceForEdit({locationId: parseInt(locationId), deviceId: parseInt(deviceId)}));
            }
        }
        else if(deviceId && context.currentLocation?.locationId) {
            dispatch(getDeviceForEdit({locationId: context.currentLocation.locationId, deviceId: parseInt(deviceId)}));
        }

    }, [context.currentLocation?.locationId, dispatch, deviceId, locationId]);
}

export const DeactivateDevice = () => {
    useAutoSelectLocationByUrl();

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const context = useAppSelector(appContextSelector);
    const deviceContext = useAppSelector(deviceContextSelector);
    const deviceStats: DeviceStatDto | undefined = deviceContext.selectedDeviceStats;
    const brandDeviceIssues = useAppSelector(sortedDeviceIssuesSelector);

    const [formDevice, setFormDevice] = React.useState<DeviceDto>({
        deviceId: undefined,
        deviceTag: '',
        deviceIdNumber: '',
        status: DEV_STATUS_INACTIVE,
        locationId: undefined,
        inactiveReason: '',
        inactiveReasonDetail: '',
        deviceType: undefined
    });

    const [navBackDeviceId, setNavBackDeviceId] = React.useState<number | undefined>(undefined);
    const [showNotes, setShowNotes] = React.useState<boolean>(false);

    const {state} = useLocation() as LocationState;
    React.useEffect(() => {
        if(state) {
            const { editingFromDeviceId } = state; // Read values passed on state
            if(editingFromDeviceId) {
                setNavBackDeviceId(editingFromDeviceId);
            }
        }
    }, [state]);

    React.useEffect(() => {
        return () => {dispatch(deviceContextActions.resetDeactivateStatus())}
    }, [dispatch]);

    const stateDevice = deviceContext.selectedDevice;

    React.useEffect(() => {
        if(stateDevice) {
            setFormDevice(stateDevice);

            if(stateDevice.deviceId && context.currentLocation?.locationId) {
                dispatch(getDeviceStats({deviceId: stateDevice.deviceId, locationId: context.currentLocation.locationId}));
            }
        }
    }, [context.currentLocation?.locationId, dispatch, stateDevice]);

    const submitForm = React.useCallback(() => {
        if(context.currentLocation?.locationId) {
            dispatch(deactivateDevice({deviceDto: formDevice, locationId: context.currentLocation?.locationId}));
        }
    }, [context.currentLocation?.locationId, dispatch, formDevice]);
    
    const nextDisabled = React.useMemo(() => {
        if (!formDevice.inactiveReason || formDevice.inactiveReason?.length === 0) {
            return true;
        } else if (formDevice.inactiveReason === INACTIVE_REASON_NONFUNCTIONAL.value) {
            if (!formDevice.deviceIssues || formDevice.deviceIssues?.length === 0) {
                if (brandDeviceIssues.length > 0) {
                    return true;
                }
            }
        }
        return false;
    },[formDevice.inactiveReason, formDevice.deviceIssues, brandDeviceIssues])

    React.useEffect(() => {
        if(deviceContext.deactivateSuccessful) {
            navigate(`/devices/view/locationId=${context.currentLocation?.locationId}/brandId=${context.currentBrand?.brandId}`, 
            {state: {editingFromDeviceId: formDevice.deviceId, editDeviceFrom: 'deactivate', editingFromDeviceIdNumber: formDevice.deviceIdNumber, editingFromDeviceTag: formDevice.deviceTag}});
            dispatch(deviceContextActions.resetDeactivateStatus());
            if (deviceContext.importResult && state?.portalUploadStatusId && state?.duplicateListUpdated) {
                dispatch(updateDuplicateList({
                    portalUploadStatusId: state?.portalUploadStatusId,
                    duplicateList: state?.duplicateListUpdated,
                }));
            }
        }
    }, [context.currentBrand?.brandId, 
              context.currentLocation?.locationId, 
              deviceContext.deactivateSuccessful, 
              dispatch, 
              formDevice.deviceId, 
              formDevice.deviceIdNumber, 
              formDevice.deviceTag, 
              navigate,
              deviceContext.importResult, 
              state?.duplicateListUpdated, 
              state?.portalUploadStatusId]);

    return  (
        <AppWithFooter>
            <AppBar/>
            <SideBar/>
            <main className={'o-main'}>
                <div className={'l-wrap l-container--lg'}>
                    {deviceContext.deactivateErrorMessage &&
                        <ServerMessage variant={'error'}>
                            <ErrorMessageSectionItem>
                                {deviceContext.deactivateErrorMessage}
                            </ErrorMessageSectionItem>
                        </ServerMessage>
                    }
                    {showNotes ?
                        <DeactivateDeviceNotes
                            formDevice={formDevice}
                            setFormDevice={setFormDevice}
                            setShowNotes={setShowNotes}
                            submitForm={submitForm}
                        />
                    :
                        <div className={'deactivate-device__form__root'}>
                            <IconButton color="primary" className={'back-button'} aria-label="back" onClick={() => {
                                if (navBackDeviceId) {
                                    navigate(`/devices/edit/locationId=${context.currentLocation?.locationId}/deviceId=${navBackDeviceId}`);
                                } else {
                                    navigate(`/devices/view/locationId=${context.currentLocation?.locationId}/brandId=${context.currentBrand?.brandId}`);
                                }
                            }}>
                                <ArrowBack className={'back-button-arrow'}/>
                            </IconButton>

                            <div style={{width: '80%'}}>
                                <div className={'deactivate-device__form__title-div'}>
                                    <div className={'deactivate-device__form__title-text'}>
                                        {getLocalizedString('deactivateDevice.title', 'Deactivate Device')} {formDevice.deviceTag}
                                    </div>
                                </div>


                                {deviceStats &&
                                    <div className={'deactivate-device__stats-div'}>
                                        <div className={'o-heading--xs'} style={{marginBottom: '1vh'}}>{getLocalizedString('deactivateDevice.stats', 'Device Stats')}</div>

                                        <div style={{display: 'flex', alignItems: 'baseline'}}>
                                            <div className={'o-heading--xs'} style={{marginRight: '1vw'}}>{getLocalizedString('deactivateDevice.stats.lastPickup', 'Last Pickup:')}</div>
                                            <div className={'o-body-text'}>
                                                {deviceStats.lastPickup}
                                            </div>
                                        </div>

                                        <div style={{display: 'flex', alignItems: 'baseline', marginBottom: '1vh'}}>
                                            <div className={'o-heading--xs'} style={{marginRight: '1vw'}}>{getLocalizedString('deactivateDevice.stats.lastReturn', 'Last Return:')}</div>
                                            <div className={'o-body-text'}>
                                                {deviceStats.lastReturn}
                                            </div>
                                        </div>

                                        <div className={'o-heading--xs'}>{getLocalizedString('deactivateDevice.stats.recentIssues', 'Most Recent Reported Issues:')}</div>
                                        {
                                            deviceStats.recentIssues?.map(issue => {
                                                return <div className={'o-body-text'}>{issue}</div>
                                            })
                                        }

                                    </div>
                                }


                                <div className={'o-heading--xs'} style={{display: 'flex'}}>
                                    <span>
                                        {getLocalizedString('deactivateDevice.inactiveReason', 'Select Deactivation Reason:')}
                                        <span style={{color: 'red'}}>*</span>
                                    </span>
                                </div>

                                <div className={'u-display--flex u-display--flex-column u-space-sm u-gap-sm'}>
                                        {
                                            Object.values(INACTIVE_REASONS).map((option, index) => {
                                                return (
                                                    <div>
                                                        {(INACTIVE_REASON_NONFUNCTIONAL.value === option.value && (brandDeviceIssues !== undefined && brandDeviceIssues?.length) > 0) ?
                                                            <div>
                                                                <ARCRadioInput
                                                                    id={'inactive-reason-radio-' + index}
                                                                    size={'md'}
                                                                    style={{marginRight: '5px'}}
                                                                    onChange={() => setFormDevice({...formDevice, inactiveReason: option.value})}
                                                                    on={formDevice.inactiveReason === option.value}
                                                                    label={getLocalizedString(option.labelKey, option.value)}
                                                                />
                                                                <div className={'u-display--flex u-display--flex-column u-space-sm u-gap-sm'}>
                                                                    {brandDeviceIssues?.map((issue, idx) => {
                                                                        return (
                                                                            <ARCCheckbox
                                                                                id={'add-device-issue-checkbox-' + idx}
                                                                                size={'md'}
                                                                                checked={!!formDevice.deviceIssues?.some(deviceIssue => deviceIssue.brandDeviceIssueId === issue.brandDeviceIssueId)}
                                                                                label={issue.deviceIssueType}
                                                                                onChange={() => {
                                                                                    if (!formDevice.deviceIssues?.find(deviceIssue => deviceIssue.brandDeviceIssueId === issue.brandDeviceIssueId)) {
                                                                                        if (formDevice.deviceIssues) {
                                                                                            setFormDevice({
                                                                                                ...formDevice,
                                                                                                inactiveReason: INACTIVE_REASON_NONFUNCTIONAL.value,
                                                                                                deviceIssues: [...formDevice.deviceIssues, issue]
                                                                                            });
                                                                                        } else {
                                                                                            setFormDevice({
                                                                                                ...formDevice,
                                                                                                inactiveReason: INACTIVE_REASON_NONFUNCTIONAL.value,
                                                                                                deviceIssues: [issue]
                                                                                            });
                                                                                        }
                                                                                    } else {
                                                                                        setFormDevice({
                                                                                            ...formDevice,
                                                                                            inactiveReason: INACTIVE_REASON_NONFUNCTIONAL.value,
                                                                                            deviceIssues: formDevice.deviceIssues?.filter(deviceIssue => deviceIssue.brandDeviceIssueId !== issue.brandDeviceIssueId)
                                                                                        })
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>

                                                            :

                                                            <ARCRadioInput
                                                                id={'inactive-reason-radio-' + index}
                                                                size={'md'}
                                                                style={{marginRight: '5px'}}
                                                                onChange={() => setFormDevice({...formDevice, inactiveReason: option.value, deviceIssues: []})}
                                                                on={formDevice.inactiveReason === option.value}
                                                                label={getLocalizedString(option.labelKey, option.value)}
                                                            />
                                                        }

                                                    </div>
                                                )
                                            })
                                        }
                                </div>

                                <div className={'deactivate-device__button-div'}>
                                    <ARCButton
                                        fill={'filled'}
                                        variant={'primary'}
                                        size={'md'}
                                        id="adduser-save"
                                        disabled={nextDisabled}
                                        onClick={() => setShowNotes(true)}
                                        label={getLocalizedString('deactivateDevice.submit', 'Next')}
                                    >
                                    </ARCButton>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </main>
        </AppWithFooter>
    )
}
